import { useState, useEffect } from "react";
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import Highlighter from "react-highlight-words";
import * as date from 'date-fns'

const queryClient = new QueryClient()

function App() {
  const [keyword, setKeyword] = useState("")
  const debouncedKeyword = useDebounce(keyword, 500)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value)
  const username = import.meta.env.VITE_TWITTER_USERNAME

  return (
    <div>
      <header>
        <p><a href={`https://twitter.com/${username}`} target="_blank">@{username}</a> tweet archive</p>
        <input type="text" onChange={handleChange} placeholder="Search by keywords" />
      </header>
      <QueryClientProvider client={queryClient}>
        <div className="result">
          <TweetsList keyword={debouncedKeyword} />
        </div>
      </QueryClientProvider>
    </div>
  );
}

interface Tweet {
  tweetId: string
  createdAt: string
  userName: string
  text: string
}

function TweetsList({ keyword }: { keyword: string }) {
  const api = keyword == "" ? `/latest/500` : `/search/${encodeURIComponent(keyword)}`
  const { isLoading, error, data, isSuccess } = useQuery<Tweet[]>(['search', keyword], () =>
    fetch(`${import.meta.env.VITE_API_HOST}${api}`).then(res => res.json()))

  if (isLoading || !data) return <p>{keyword == "" ? `Loading...` : `Searching... '${keyword}'`}</p>
  if (error || !isSuccess) return <p>{`error: ${error}`}</p>

  return (
    <ul>
      {data.map(tweet => <li key={tweet.tweetId}>
        <a href={`https://twitter.com/${tweet.userName}/status/${tweet.tweetId}`} target="_blank">
          <span className="date">{date.format(date.parseISO(tweet.createdAt), 'yyyy/MM/dd HH:mm:ss')}</span>
          <Highlighter searchWords={[keyword]} textToHighlight={tweet.text} />
        </a>
      </li>)}
    </ul>
  )
}


export function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const timer = setTimeout(() => { setDebouncedValue(value) }, delay)
    return () => { clearTimeout(timer) }
  }, [value, delay]);
  return debouncedValue
}

export default App;
